import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter
} from "react-router-dom";
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import {telosTestnet} from '@wagmi/core/chains'
const { chains, provider, webSocketProvider } = configureChains(
  [telosTestnet],
   [ publicProvider({ priority: 1 })
 ]
);

  const client = createClient({
    autoConnect: true,
    connectors:[
      new MetaMaskConnector({ chains }),
    ],
    provider,
    webSocketProvider,
  })
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
     <BrowserRouter>
    <App />
    </BrowserRouter>
    </WagmiConfig>
  </React.StrictMode>
);

